var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var lX,mX,yma,zma,Ama,oX,pX,qX,rX,tX,Bma,vX;lX=function(a){switch(arguments.length){case 2:return $CLJS.nV(arguments[0],arguments[1]);case 3:return $CLJS.mV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};mX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};yma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
zma=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.nX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Ama=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);oX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
pX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);qX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);rX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.sX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
tX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Bma=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.uX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);vX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.wX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.AL.o(null,$CLJS.FG,function(){return $CLJS.ml});$CLJS.iL(vX,$CLJS.lk);$CLJS.AL.o(null,$CLJS.LG,function(){return $CLJS.yl});$CLJS.iL(vX,$CLJS.lk);$CLJS.iL(Bma,$CLJS.dk);$CLJS.AL.o(null,$CLJS.uG,function(){return $CLJS.hl});$CLJS.iL(zma,$CLJS.tm);$CLJS.AL.o(null,$CLJS.sG,function(){return $CLJS.JK});$CLJS.iL(Ama,$CLJS.Ml);
var Cma=[":\\d{2}",mX($CLJS.G(["\\.\\d{1,6}"]))].join(""),xX=["\\d{2}:\\d{2}",mX($CLJS.G([Cma]))].join(""),yX=["\\d{4}-\\d{2}-\\d{2}T",xX].join(""),zX=["(?:Z|(?:[+-]",xX,"))"].join(""),Dma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Ema=$CLJS.Fi(["^",xX,"$"].join("")),Fma=$CLJS.Fi(["^",xX,zX,"$"].join("")),Gma=$CLJS.Fi(["^",yX,"$"].join("")),Hma=$CLJS.Fi(["^",yX,zX,"$"].join(""));$CLJS.iL(qX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"date string literal"],null),Dma],null));
$CLJS.iL(pX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"local time string literal"],null),Ema],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"offset time string literal"],null),Fma],null)],null));
$CLJS.iL(rX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"local date time string literal"],null),Gma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"offset date time string literal"],null),Hma],null)],null));
$CLJS.AL.o(null,$CLJS.tG,function(a){return $CLJS.m($CLJS.nV?$CLJS.nV(rX,a):lX.call(null,rX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Rl,null,$CLJS.RJ,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(qX,a):lX.call(null,qX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.NI,null,$CLJS.Rl,null],null),null):$CLJS.m($CLJS.nV?$CLJS.nV(pX,a):lX.call(null,pX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.WK,null,$CLJS.Rl,null],null),null):$CLJS.Rl});$CLJS.iL($CLJS.sX,qX);$CLJS.iL(oX,pX);
$CLJS.iL($CLJS.wX,rX);$CLJS.iL(yma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Bk,$CLJS.sX,oX,$CLJS.wX],null));$CLJS.AX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.iL($CLJS.uX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"year-month string literal"],null),$CLJS.AX],null));$CLJS.BX=$CLJS.Fi("^\\d{4}$");$CLJS.iL($CLJS.nX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.cv,"year string literal"],null),$CLJS.BX],null));
$CLJS.iL(tX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.wL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mk,$CLJS.xL],null)],null)],null));$CLJS.sV($CLJS.pl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ek,new $CLJS.k(null,1,[$CLJS.cv,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.pl],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,tX],null),$CLJS.Lb],null));