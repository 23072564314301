var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Vsa,t7,u7,Wsa,Xsa,Ysa,Zsa,z7,A7,C7,D7,G7,H7,$sa,x7,ata;$CLJS.o7=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.fZ);b=$CLJS.c3(a,b);return 0<b?b-1:null};$CLJS.p7=function(a,b,c,d){d=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,4,[$CLJS.E3,$CLJS.$1(),$CLJS.C3,!0,$CLJS.I3,!0,$CLJS.A3,!0],null),d]));return $CLJS.Q3.D(a,b,c,d)};$CLJS.q7=function(a,b,c){return $CLJS.p7(a,b,c,null)};Vsa=function(a,b){b=$CLJS.j3(a,b);return $CLJS.m(b)?$CLJS.f4(a,b):null};
$CLJS.r7=function(a){var b=$CLJS.BH(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "field":return $CLJS.lQ.g($CLJS.DH(a));case "metadata/column":return $CLJS.k4.g(a);case "mbql/join":return $CLJS.vP.g(a);default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.s7=function(a){return $CLJS.S6.g(a)};t7=function(a,b){return $CLJS.F.h($CLJS.i6.g(a),$CLJS.li(b,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.VK,$CLJS.BI,$CLJS.SK],null)))};
u7=function(a){var b=new $CLJS.k(null,3,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.CG,$CLJS.CG.g(a),$CLJS.mk,$CLJS.Nm.h($CLJS.mk,$CLJS.CG)(a)],null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zG,b,$CLJS.V.g(a)],null)};Wsa=function(a){return $CLJS.T3(a,new $CLJS.k(null,1,[$CLJS.VK,$CLJS.Oi],null))};
Xsa=function(a,b,c){var d=$CLJS.fn.l;b=$CLJS.Gb($CLJS.o7(a,b))?function(){var f=$CLJS.vZ.g($CLJS.C($CLJS.fZ.g(a)));return $CLJS.m(f)?(f=Vsa(a,f),$CLJS.m(f)?$CLJS.tW(function(g){return $CLJS.F.h($CLJS.Gk.g(g),c)},f):null):null}():null;try{var e=$CLJS.b4(a,c)}catch(f){e=null}return d.call($CLJS.fn,$CLJS.G([b,e]))};
Ysa=function(a,b){var c=$CLJS.tW(function(d){return $CLJS.F.h($CLJS.U2.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.tW(function(d){return $CLJS.F.h($CLJS.V.g(d),a)},b);if($CLJS.m(c))return c;c=$CLJS.XF($CLJS.XD);$CLJS.m($CLJS.dG("metabase.lib.field",c))&&(b=$CLJS.TH("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.aj.l($CLJS.G([a])),$CLJS.aj.l($CLJS.G([$CLJS.Pm.h($CLJS.U2,b)]))])),b instanceof Error?$CLJS.eG("metabase.lib.field",c,$CLJS.UB(),b):$CLJS.eG("metabase.lib.field",
c,$CLJS.UB.l($CLJS.G([b])),null));return null};
Zsa=function(a,b,c){if($CLJS.m(v7))return null;var d=v7;v7=!0;try{var e=$CLJS.o7(a,b),f=$CLJS.m(e)?$CLJS.d3(a,e):$CLJS.d3(a,b),g=function(){var n=$CLJS.w7.g(f);if($CLJS.m(n))return n;n=$CLJS.Iu(f,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mZ,$CLJS.a0],null));if($CLJS.m(n))return n;n=$CLJS.m($CLJS.vZ.g(f))?$CLJS.q7(a,b,f):null;if($CLJS.m(n))return n;n=$CLJS.XF($CLJS.XD);if($CLJS.m($CLJS.dG("metabase.lib.field",n))){var q=$CLJS.TH("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.aj.l($CLJS.G([c]))]));
return q instanceof Error?$CLJS.eG("metabase.lib.field",n,$CLJS.UB(),q):$CLJS.eG("metabase.lib.field",n,$CLJS.UB.l($CLJS.G([q])),null)}return null}(),l=function(){var n=$CLJS.A(g);return n?Ysa(c,g):n}();return $CLJS.m(l)?$CLJS.m(e)?$CLJS.U.j($CLJS.U.j($CLJS.G5($CLJS.Dm.l(l,$CLJS.Gk,$CLJS.G([$CLJS.i0,x7,$CLJS.y7])),null),$CLJS.V,function(){var n=$CLJS.U2.g(l);return $CLJS.m(n)?n:$CLJS.V.g(l)}()),$CLJS.d0,$CLJS.P2):l:null}finally{v7=d}};
z7=function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d);d=$CLJS.M.h(e,$CLJS.lQ);var f=$CLJS.J(c,2,null);c=$CLJS.fn.l($CLJS.G([function(){var g=$CLJS.CG.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.CG,g],null):null}(),function(){var g=$CLJS.Nm.h($CLJS.mk,$CLJS.CG)(e);return $CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.mk,g],null):null}(),function(){var g=$CLJS.OR.g(e);return $CLJS.m(g)?new $CLJS.k(null,1,[x7,g],null):null}(),function(){var g=$CLJS.PQ.g(e);return $CLJS.m(g)?new $CLJS.k(null,
1,[$CLJS.y7,g],null):null}(),$CLJS.Ae(f)?function(){var g=Xsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.uz,$CLJS.gW,$CLJS.V,f],null)}():$CLJS.m(d)?new $CLJS.k(null,2,[$CLJS.uz,$CLJS.gW,$CLJS.V,f],null):function(){var g=Zsa(a,b,f);return $CLJS.m(g)?g:new $CLJS.k(null,2,[$CLJS.uz,$CLJS.gW,$CLJS.V,f],null)}()]));return $CLJS.m(d)?$CLJS.G5(c,d):c};A7=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.y7);return $CLJS.m($CLJS.m(b)?$CLJS.Ie($CLJS.UV,b):b)?$CLJS.hl:$CLJS.Nm.h($CLJS.mk,$CLJS.CG)(a)};
$CLJS.B7=function(a,b){return"string"===typeof b?$CLJS.j3(a,$CLJS.c0(b)):$CLJS.Ae(b)?$CLJS.i3(a,b):null};C7=function(a,b,c){return $CLJS.m($CLJS.Xf(function(d){return $CLJS.F.h($CLJS.Wj.g(d),c)},a))?$CLJS.Pm.h(function(d){var e=$CLJS.Dm.h(d,b);return $CLJS.F.h($CLJS.Wj.g(d),c)?$CLJS.U.j(e,b,!0):e},a):a};
D7=function(a){var b=function(){var d=$CLJS.d0.g(a),e=new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.W2,null,$CLJS.Y2,null,$CLJS.P2,null],null),null);return e.g?e.g(d):e.call(null,d)}(),c=$CLJS.fn.l($CLJS.G([new $CLJS.k(null,3,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.CG,$CLJS.CG.g(a),$CLJS.mk,A7(a)],null),function(){var d=$CLJS.r7(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.lQ,d],null):null}(),function(){var d=$CLJS.y7.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.PQ,d],null):null}(),function(){var d=
x7.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.OR,d],null):null}(),function(){var d=$CLJS.iR.g(a);return $CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.VO,d],null):null}()]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,$CLJS.m(b)?function(){var d=$CLJS.U2.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}():function(){var d=$CLJS.Gk.g(a);return $CLJS.m(d)?d:$CLJS.V.g(a)}()],null)};
$CLJS.E7=function E7(a){switch(arguments.length){case 2:return E7.h(arguments[0],arguments[1]);case 3:return E7.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.E7.h=function(a,b){return $CLJS.E7.j(a,-1,b)};$CLJS.E7.j=function(a,b,c){return $CLJS.t4.j(a,b,c)};$CLJS.E7.v=3;
$CLJS.F7=function F7(a){switch(arguments.length){case 2:return F7.h(arguments[0],arguments[1]);case 3:return F7.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.F7.h=function(a,b){return $CLJS.F7.j(a,-1,b)};$CLJS.F7.j=function(a,b,c){return $CLJS.T6.j(a,b,c)};$CLJS.F7.v=3;$CLJS.y7=new $CLJS.N("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);
G7=new $CLJS.N(null,"parent-id","parent-id",-1400729131);$CLJS.w7=new $CLJS.N("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);H7=new $CLJS.N("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);$sa=new $CLJS.N(null,"earliest","earliest",-1928154382);x7=new $CLJS.N("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);ata=new $CLJS.N(null,"latest","latest",24323665);$CLJS.U3.o(null,$CLJS.DQ,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Oi.g(b),$CLJS.T3(c,new $CLJS.k(null,2,[$CLJS.PQ,$CLJS.Oi,$CLJS.OR,Wsa],null)),a],null)});var v7=!1,I7=function I7(a,b){var d=$CLJS.b4(a,G7.g(b));a=$CLJS.m(G7.g(d))?I7.h?I7.h(a,d):I7.call(null,a,d):d;a=$CLJS.Qf(a);var e=$CLJS.M.h(a,$CLJS.V);return $CLJS.Qm.j(b,$CLJS.V,function(f){return[$CLJS.p.g(e),".",$CLJS.p.g(f)].join("")})};
$CLJS.N3.o(null,$CLJS.gW,function(a,b,c){return A7(c)});$CLJS.N3.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.PQ);$CLJS.J(c,2,null);c=z7(a,b,c);c=$CLJS.m(d)?$CLJS.U.j(c,$CLJS.y7,d):c;return $CLJS.O3.j(a,b,c)});$CLJS.k3.o(null,$CLJS.gW,function(a,b,c){a=$CLJS.Qf(c);b=$CLJS.M.h(a,$CLJS.V);return $CLJS.U.j(a,$CLJS.V,b)});
$CLJS.k3.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);var d=$CLJS.J(c,1,null),e=$CLJS.Qf(d),f=$CLJS.M.h(e,$CLJS.HG),g=$CLJS.M.h(e,$CLJS.CG),l=$CLJS.M.h(e,$CLJS.OR),n=$CLJS.M.h(e,$CLJS.mk);d=$CLJS.M.h(e,$CLJS.lQ);var q=$CLJS.M.h(e,$CLJS.VO),u=$CLJS.M.h(e,$CLJS.PQ),v=z7(a,b,c),x=$CLJS.fn.l;f=new $CLJS.k(null,2,[$CLJS.uz,$CLJS.gW,$CLJS.H3,f],null);e=$CLJS.SI.g(e);b=$CLJS.m(e)?e:$CLJS.m3.j(a,b,c);b=x.call($CLJS.fn,$CLJS.G([f,v,new $CLJS.k(null,1,[$CLJS.SI,b],null),$CLJS.m(n)?new $CLJS.k(null,1,[$CLJS.mk,
n],null):null,$CLJS.m(g)?new $CLJS.k(null,1,[$CLJS.CG,g],null):null,$CLJS.m(u)?new $CLJS.k(null,1,[$CLJS.y7,u],null):null,$CLJS.m(l)?new $CLJS.k(null,1,[x7,l],null):null,$CLJS.m(q)?new $CLJS.k(null,1,[$CLJS.iR,q],null):null]));d=$CLJS.m(d)?$CLJS.G5(b,d):b;return $CLJS.m(G7.g(d))?I7(a,d):d});
$CLJS.J3.o(null,$CLJS.gW,function(a,b,c,d){c=$CLJS.Qf(c);var e=$CLJS.M.h(c,$CLJS.SI),f=$CLJS.M.h(c,$CLJS.V),g=$CLJS.M.h(c,$CLJS.Wj),l=$CLJS.M.h(c,x7),n=$CLJS.M.h(c,$CLJS.a3),q=$CLJS.M.h(c,$CLJS.iR),u=$CLJS.M.h(c,$CLJS.i0);e=$CLJS.m(e)?e:"string"===typeof f?$CLJS.$3.h($CLJS.Z3,f):$CLJS.p.g(f);$CLJS.F.h(d,$CLJS.n3)&&-1==e.indexOf(" → ")?($CLJS.m(q)?(q=$CLJS.b4(a,q),$CLJS.m(q)?a=(0,$CLJS.Ca)($CLJS.Mu($CLJS.SI.g($CLJS.p3.j(a,b,q)),$CLJS.l0,"")):(u=$CLJS.B7(a,u),a=$CLJS.m3.D(a,b,u,d))):a=null,n=$CLJS.m(a)?
a:$CLJS.m(n)?n:$CLJS.r7(c)):n=null;n=$CLJS.m(n)?[$CLJS.p.g(n)," → ",$CLJS.p.g(e)].join(""):e;return $CLJS.m(g)?(c=$CLJS.lG($CLJS.Mu($CLJS.ui(g),"-"," ")),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):$CLJS.m(l)?(c=$CLJS.P6(l,c),$CLJS.xa.j?$CLJS.xa.j("%s: %s",n,c):$CLJS.xa.call(null,"%s: %s",n,c)):n});
$CLJS.J3.o(null,$CLJS.DQ,function(a,b,c,d){$CLJS.J(c,0,null);var e=$CLJS.J(c,1,null),f=$CLJS.Qf(e);e=$CLJS.M.h(f,$CLJS.OR);var g=$CLJS.M.h(f,$CLJS.lQ),l=$CLJS.M.h(f,$CLJS.PQ);f=$CLJS.M.h(f,$CLJS.VO);$CLJS.J(c,2,null);c=z7(a,b,c);c=$CLJS.m(g)?$CLJS.U.j(c,$CLJS.a3,g):c;l=$CLJS.m(l)?$CLJS.U.j(c,$CLJS.Wj,l):c;e=$CLJS.m(e)?$CLJS.U.j(l,x7,e):l;e=$CLJS.m(f)?$CLJS.U.j(e,$CLJS.iR,f):e;return $CLJS.m(e)?$CLJS.m3.D(a,b,e,d):$CLJS.UH("[Unknown Field]")});
$CLJS.K3.o(null,$CLJS.gW,function(a,b,c){a=$CLJS.Qf(c);return $CLJS.M.h(a,$CLJS.V)});$CLJS.K3.o(null,$CLJS.DQ,function(a,b,c){$CLJS.J(c,0,null);$CLJS.J(c,1,null);c=z7(a,b,c);return $CLJS.m(c)?$CLJS.L3.j(a,b,c):"unknown_field"});
$CLJS.P3.o(null,$CLJS.gW,function(a,b,c){return $CLJS.fn.l($CLJS.G([function(){var d=$CLJS.tj($CLJS.P3,$CLJS.Pj);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.h($CLJS.d0.g(c),$CLJS.W2)?function(){var d=$CLJS.e4.g(c);return $CLJS.m(d)?(d=$CLJS.j3(a,d),$CLJS.m(d)?new $CLJS.k(null,1,[$CLJS.j0,new $CLJS.k(null,2,[$CLJS.V,$CLJS.V.g(d),$CLJS.SI,$CLJS.V.g(d)],null)],null):null):null}():null]))});$CLJS.s4.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);$CLJS.J(a,2,null);return $CLJS.PQ.g(b)});
$CLJS.s4.o(null,$CLJS.gW,function(a){return $CLJS.y7.g(a)});
$CLJS.r4.o(null,$CLJS.DQ,function(a,b){$CLJS.J(a,0,null);var c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(b)){var d=$CLJS.Ie($CLJS.UV,b),e=$CLJS.Nm.j(H7,$CLJS.mk,$CLJS.CG)(c);c=$CLJS.U.l(c,$CLJS.PQ,b,$CLJS.G([$CLJS.mk,d?$CLJS.hl:e,H7,e]));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,a],null)}b=H7.g(c);c=$CLJS.m(b)?$CLJS.Dm.h($CLJS.U.j(c,$CLJS.mk,b),H7):c;c=$CLJS.Dm.h(c,$CLJS.PQ);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,a],null)});
$CLJS.r4.o(null,$CLJS.gW,function(a,b){return $CLJS.m(b)?$CLJS.U.j(a,$CLJS.y7,b):$CLJS.Dm.h(a,$CLJS.y7)});$CLJS.t4.o(null,$CLJS.DQ,function(a,b,c){return $CLJS.E7.j(a,b,z7(a,b,c))});
$CLJS.t4.o(null,$CLJS.gW,function(a,b,c){if($CLJS.ki.h($CLJS.d0.g(c),$CLJS.R2)){a=$CLJS.Nm.h($CLJS.mk,$CLJS.CG)(c);b=null==c?null:$CLJS.dP.g(c);if(null==b)var d=null;else try{var e=$CLJS.RJ.g($CLJS.Vk.g(b));if($CLJS.m(e)){var f=$CLJS.Qf(e),g=$CLJS.M.h(f,$sa),l=$CLJS.M.h(f,ata),n=$CLJS.Usa.l($CLJS.G([$CLJS.n7.g(g),$CLJS.n7.g(l)]));d=isNaN(n)?null:$CLJS.m($CLJS.rt.h?$CLJS.rt.h(1,n):$CLJS.rt.call(null,1,n))?$CLJS.tz:$CLJS.m($CLJS.rt.h?$CLJS.rt.h(31,n):$CLJS.rt.call(null,31,n))?$CLJS.wz:$CLJS.m($CLJS.rt.h?
$CLJS.rt.h(365,n):$CLJS.rt.call(null,365,n))?$CLJS.Xk:$CLJS.il}else d=null}catch(q){if(q instanceof Error)d=null;else throw q;}e=$CLJS.NH(a,$CLJS.RJ)?$CLJS.Vqa:$CLJS.NH(a,$CLJS.NI)?$CLJS.Uqa:$CLJS.NH(a,$CLJS.WK)?$CLJS.Tqa:$CLJS.Lg;d=$CLJS.m(d)?C7(e,$CLJS.Pj,d):e;return $CLJS.m($CLJS.y7.g(c))?C7(d,$CLJS.u3,$CLJS.y7.g(c)):d}return $CLJS.Lg});
$CLJS.S6.o(null,$CLJS.DQ,function(a){var b=null==a?null:$CLJS.DH(a);b=null==b?null:$CLJS.OR.g(b);return null==b?null:$CLJS.U.l(b,$CLJS.uz,$CLJS.Q6,$CLJS.G([$CLJS.y3,function(c,d){return z7(c,d,a)}]))});$CLJS.S6.o(null,$CLJS.gW,function(a){var b=null==a?null:x7.g(a);return null==b?null:$CLJS.U.l(b,$CLJS.uz,$CLJS.Q6,$CLJS.G([$CLJS.y3,$CLJS.ag(a)]))});$CLJS.R6.o(null,$CLJS.DQ,function(a,b){return $CLJS.cW(a,$CLJS.bW,$CLJS.G([$CLJS.OR,b]))});
$CLJS.R6.o(null,$CLJS.gW,function(a,b){return $CLJS.bW(a,x7,b)});$CLJS.T6.o(null,$CLJS.DQ,function(a,b,c){return $CLJS.F7.j(a,b,z7(a,b,c))});
$CLJS.T6.o(null,$CLJS.gW,function(a,b,c){b=$CLJS.Qf(c);c=$CLJS.M.h(b,$CLJS.mk);var d=$CLJS.M.h(b,$CLJS.dP),e=$CLJS.M.h(b,$CLJS.Il);if($CLJS.ki.h($CLJS.d0.g(b),$CLJS.R2)){var f=function(){var n=null==a?null:$CLJS.V3($CLJS.h3(a));n=null==n?null:$CLJS.wF.g(n);return null==n?null:$CLJS.Ie(n,$CLJS.OR)}(),g=$CLJS.Iu(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Vk,$CLJS.Cl],null)),l=$CLJS.s7(b);return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),
y=$CLJS.E(x),B=$CLJS.Bf(y);return function(){for(var I=0;;)if(I<y){var Q=$CLJS.be(x,I),Y=B,aa=Q;Q=t7(Q,l)?$CLJS.U.j(aa,$CLJS.u3,!0):aa;Y.add(Q);I+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}var H=$CLJS.C(v);return $CLJS.nf(function(){var I=H;return t7(H,l)?$CLJS.U.j(I,$CLJS.u3,!0):I}(),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Gb(function(){if($CLJS.m(f)){var q=$CLJS.qk.g(g);return $CLJS.m(q)?$CLJS.Xl.g(g):q}return f}())?null:$CLJS.NH(e,$CLJS.kl)?$CLJS.r($CLJS.psa):
$CLJS.NH(c,$CLJS.Cl)&&!$CLJS.NH(e,$CLJS.Qj)?$CLJS.r($CLJS.osa):null)}return $CLJS.Lg});$CLJS.aW.o(null,$CLJS.DQ,function(a){return a});
$CLJS.aW.o(null,$CLJS.gW,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.d0);switch(b instanceof $CLJS.N?b.S:null){case "source/aggregations":return b=new $CLJS.k(null,2,[$CLJS.HG,$CLJS.p.g($CLJS.nL()),$CLJS.mk,$CLJS.Nm.h($CLJS.mk,$CLJS.CG)(a)],null),a=$CLJS.H3.g(a),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,b,a],null);case "source/expressions":return u7(a);case "source/breakouts":return $CLJS.Ie(a,$CLJS.LL)?u7(a):D7(a);default:return D7(a)}});