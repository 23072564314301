var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var Vma;$CLJS.gY=new $CLJS.N("mbql.filter","operator","mbql.filter/operator",-1267834132);Vma=new $CLJS.N("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);for(var hY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk],null)),iY=null,jY=0,kY=0;;)if(kY<jY){var Wma=iY.$(null,kY);$CLJS.yV(Wma,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.IL],null)],null)],null)],null)]));kY+=1}else{var lY=$CLJS.A(hY);if(lY){var mY=lY;if($CLJS.re(mY)){var nY=$CLJS.$c(mY),Xma=$CLJS.ad(mY),
Yma=nY,Zma=$CLJS.E(nY);hY=Xma;iY=Yma;jY=Zma}else{var $ma=$CLJS.C(mY);$CLJS.yV($ma,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.IL],null)],null)],null)],null)]));hY=$CLJS.D(mY);iY=null;jY=0}kY=0}else break}$CLJS.wV($CLJS.Sk,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.IL],null)]));
for(var oY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.BK],null)),pY=null,qY=0,rY=0;;)if(rY<qY){var ana=pY.$(null,rY);$CLJS.yV(ana,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.ML],null)],null)],null)],null)]));rY+=1}else{var sY=$CLJS.A(oY);if(sY){var tY=sY;if($CLJS.re(tY)){var uY=$CLJS.$c(tY),bna=$CLJS.ad(tY),
cna=uY,dna=$CLJS.E(uY);oY=bna;pY=cna;qY=dna}else{var ena=$CLJS.C(tY);$CLJS.yV(ena,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.ML],null)],null)],null)],null)]));oY=$CLJS.D(tY);pY=null;qY=0}rY=0}else break}
for(var vY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gm,$CLJS.sm,$CLJS.im,$CLJS.rm],null)),wY=null,xY=0,yY=0;;)if(yY<xY){var fna=wY.$(null,yY);$CLJS.wV(fna,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null)]));yY+=1}else{var zY=$CLJS.A(vY);if(zY){var AY=zY;if($CLJS.re(AY)){var BY=$CLJS.$c(AY),gna=$CLJS.ad(AY),hna=BY,ina=$CLJS.E(BY);vY=gna;wY=hna;xY=ina}else{var jna=$CLJS.C(AY);$CLJS.wV(jna,$CLJS.G([$CLJS.wu,$CLJS.yl,
new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null)]));vY=$CLJS.D(AY);wY=null;xY=0}yY=0}else break}$CLJS.wV($CLJS.II,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null)]));
$CLJS.wV($CLJS.TI,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.OL],null)]));
for(var CY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wI,$CLJS.mJ],null)),DY=null,EY=0,FY=0;;)if(FY<EY){var kna=DY.$(null,FY);$CLJS.wV(kna,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.JL],null)]));FY+=1}else{var GY=$CLJS.A(CY);if(GY){var HY=GY;if($CLJS.re(HY)){var IY=$CLJS.$c(HY),lna=$CLJS.ad(HY),mna=IY,nna=$CLJS.E(IY);CY=lna;DY=mna;EY=nna}else{var ona=$CLJS.C(HY);$CLJS.wV(ona,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.JL],null)]));CY=$CLJS.D(HY);
DY=null;EY=0}FY=0}else break}
for(var JY=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.JI,$CLJS.ZI],null)),KY=null,LY=0,MY=0;;)if(MY<LY){var pna=KY.$(null,MY);$CLJS.wV(pna,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.PL],null)]));MY+=1}else{var NY=$CLJS.A(JY);if(NY){var OY=NY;if($CLJS.re(OY)){var PY=$CLJS.$c(OY),qna=$CLJS.ad(OY),rna=PY,sna=$CLJS.E(PY);JY=qna;KY=rna;LY=sna}else{var tna=$CLJS.C(OY);$CLJS.wV(tna,$CLJS.G([$CLJS.wu,$CLJS.yl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.PL],null)]));JY=$CLJS.D(OY);
KY=null;LY=0}MY=0}else break}
for(var QY=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZJ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.lk],null)],null),RY=$CLJS.A(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.tJ,$CLJS.lK,$CLJS.JJ,$CLJS.uJ],null)),SY=null,TY=0,UY=0;;)if(UY<TY){var VY=SY.$(null,UY);$CLJS.tV(VY,$CLJS.yl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,VY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,$CLJS.wL,QY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.PL],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.PL],null)],null));UY+=1}else{var WY=$CLJS.A(RY);if(WY){var XY=WY;if($CLJS.re(XY)){var YY=$CLJS.$c(XY),una=$CLJS.ad(XY),vna=YY,wna=$CLJS.E(YY);RY=una;SY=vna;TY=wna}else{var ZY=$CLJS.C(XY);$CLJS.tV(ZY,$CLJS.yl,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,ZY],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,$CLJS.wL,QY],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.PL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.PL],
null)],null));RY=$CLJS.D(XY);SY=null;TY=0}UY=0}else break}
$CLJS.tV($CLJS.pK,$CLJS.yl,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.pK],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fu,$CLJS.wL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wK,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.lk],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.HL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.$l,$CLJS.mB,$CLJS.oD,$CLJS.QI],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.mq,$CLJS.RL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.mL],null)],null));$CLJS.tV($CLJS.mI,$CLJS.yl,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.ek,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.mI],null),$CLJS.wL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.qL,$CLJS.sL],null)],null));
$CLJS.iL(Vma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.gY],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AV,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.$l,$CLJS.fm,$CLJS.BK,$CLJS.TI,$CLJS.II,$CLJS.gm,$CLJS.im,$CLJS.sm,$CLJS.rm,$CLJS.wI,$CLJS.mJ,$CLJS.JI,$CLJS.ZI,$CLJS.JJ,$CLJS.uJ,$CLJS.tJ,$CLJS.lK],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SI,$CLJS.Ml],null)],null));