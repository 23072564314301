var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var uW,vW,Tla,xW,Ula,Vla,Wla,yW,wW;$CLJS.tW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};uW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.NH(b,$CLJS.NI)?$CLJS.IV:$CLJS.NH(b,$CLJS.WK)?$CLJS.EV:$CLJS.NH(b,$CLJS.RJ)?$CLJS.mL:null;return $CLJS.m(b)?$CLJS.nV(b,a):!0};
vW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.NH($CLJS.BL(d),$CLJS.kJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.BL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(uW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Tla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.cv,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.sl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.wL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,wW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.HL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,wW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.ev,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.pl);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(vW(b))].join("")}],null),$CLJS.$f(vW)],null)],null)};
xW=function(a){var b=$CLJS.T,c=Tla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sl,new $CLJS.k(null,1,[$CLJS.cv,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.wL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.Bk,c,a],null)};Ula=function(a){return $CLJS.Ve($CLJS.kV,$CLJS.rg.h(function(b){var c=$CLJS.BL(b),d=$CLJS.NH(c,$CLJS.KL);b=d?$CLJS.nV($CLJS.BV,b):d;return $CLJS.m(b)?$CLJS.Cl:c},a))};Vla=function(a){a=$CLJS.tW(function(b){return!$CLJS.NH(b,$CLJS.kJ)},$CLJS.rg.h($CLJS.BL,a));return $CLJS.NH(a,$CLJS.KL)?$CLJS.Jl:a};Wla=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.NH($CLJS.BL(b),$CLJS.kJ)},a))?Vla(a):Ula(a)};
yW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.zW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);wW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.iL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Et,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.FL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.HL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,wW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.cv,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.BL(a);return $CLJS.Wf(function(d){return uW(d,c)},b)}],null)],null));
$CLJS.iL(yW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)],null));$CLJS.sV($CLJS.km,xW($CLJS.km));$CLJS.sV($CLJS.wu,xW($CLJS.wu));$CLJS.yV($CLJS.lm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,yW],null)]));$CLJS.yV($CLJS.OK,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,yW],null)]));
for(var AW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.km,$CLJS.wu,$CLJS.lm],null)),BW=null,CW=0,DW=0;;)if(DW<CW){var Xla=BW.$(null,DW);$CLJS.lV(Xla,$CLJS.zW);DW+=1}else{var EW=$CLJS.A(AW);if(EW){var FW=EW;if($CLJS.re(FW)){var GW=$CLJS.$c(FW),Yla=$CLJS.ad(FW),Zla=GW,$la=$CLJS.E(GW);AW=Yla;BW=Zla;CW=$la}else{var ama=$CLJS.C(FW);$CLJS.lV(ama,$CLJS.zW);AW=$CLJS.D(FW);BW=null;CW=0}DW=0}else break}$CLJS.AL.o(null,$CLJS.zW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Wla(a)});
$CLJS.wV($CLJS.sI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)]));$CLJS.lV($CLJS.sI,$CLJS.NL);
for(var HW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.QK,$CLJS.YH,$CLJS.nJ],null)),IW=null,JW=0,KW=0;;)if(KW<JW){var bma=IW.$(null,KW);$CLJS.wV(bma,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)]));KW+=1}else{var LW=$CLJS.A(HW);if(LW){var MW=LW;if($CLJS.re(MW)){var NW=$CLJS.$c(MW),cma=$CLJS.ad(MW),dma=NW,ema=$CLJS.E(NW);HW=cma;IW=dma;JW=ema}else{var fma=$CLJS.C(MW);$CLJS.wV(fma,$CLJS.G([$CLJS.wu,$CLJS.JK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)]));HW=$CLJS.D(MW);IW=null;JW=0}KW=0}else break}
for(var OW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.iI,$CLJS.FJ,$CLJS.UJ],null)),PW=null,QW=0,RW=0;;)if(RW<QW){var gma=PW.$(null,RW);$CLJS.wV(gma,$CLJS.G([$CLJS.wu,$CLJS.hl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)]));RW+=1}else{var SW=$CLJS.A(OW);if(SW){var TW=SW;if($CLJS.re(TW)){var UW=$CLJS.$c(TW),hma=$CLJS.ad(TW),ima=UW,jma=$CLJS.E(UW);OW=hma;PW=ima;QW=jma}else{var kma=$CLJS.C(TW);$CLJS.wV(kma,$CLJS.G([$CLJS.wu,$CLJS.hl,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)]));OW=$CLJS.D(TW);PW=null;QW=0}RW=0}else break}$CLJS.wV($CLJS.lI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mq,$CLJS.QL],null)],null)]));
$CLJS.AL.o(null,$CLJS.lI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.NH($CLJS.BL(b),$CLJS.hl)&&$CLJS.NH($CLJS.BL(a),$CLJS.hl)?$CLJS.hl:$CLJS.JK});