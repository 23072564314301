var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var pL,xga,tL,yL;
$CLJS.nL=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return $CLJS.Gu([$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-4",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(b),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),"-",$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),
$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a()),$CLJS.p.g(a())].join(""))};$CLJS.oL=function(a){if("string"===typeof a){var b=$CLJS.Di(/[+-]?\d+/,a);return $CLJS.m(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.io(a));};pL=function(a){return $CLJS.NH(a,$CLJS.bl)||$CLJS.NH(a,$CLJS.Qj)};
$CLJS.qL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-zero","metabase.lib.schema.common/int-greater-than-zero",1130338092);xga=new $CLJS.N("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.rL=new $CLJS.N("lib","external-op","lib/external-op",-1470923877);$CLJS.sL=new $CLJS.N("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);
tL=new $CLJS.N("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.uL=new $CLJS.N(null,"database-type","database-type",-426840562);$CLJS.vL=new $CLJS.N("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.wL=new $CLJS.N("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.xL=new $CLJS.N("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);yL=new $CLJS.N("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.iL($CLJS.sL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ml,new $CLJS.k(null,1,[$CLJS.qk,1],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,1,[$CLJS.cv,"non-blank string"],null),$CLJS.$f($CLJS.OF)],null)],null));$CLJS.iL($CLJS.vL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.k(null,1,[$CLJS.qk,0],null)],null));$CLJS.iL($CLJS.qL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dk,new $CLJS.k(null,1,[$CLJS.qk,1],null)],null));
$CLJS.iL(yL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ml,new $CLJS.k(null,2,[$CLJS.qk,36,$CLJS.Xl,36],null)],null));$CLJS.iL(tL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,2,[$CLJS.cv,"valid semantic type",$CLJS.ev,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.pl);return["Not a valid semantic type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),pL],null));
$CLJS.iL($CLJS.xL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Kt,new $CLJS.k(null,2,[$CLJS.cv,"valid base type",$CLJS.ev,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.pl);return["Not a valid base type: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.NH(a,$CLJS.ml)&&!pL(a)}],null));
$CLJS.iL($CLJS.wL,new $CLJS.S(null,8,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HG,yL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.xL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.xL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Il,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,
tL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.uL,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.sL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.V,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.sL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SI,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Mt,$CLJS.sL],null)],null)],null));
$CLJS.iL(xga,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.rL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OJ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.Ml,$CLJS.Ek],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Nk,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.wL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ll,$CLJS.Ut],null)],null)],null));