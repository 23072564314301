var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");
'use strict';var Poa,o0,p0,q0,n0,r0,Qoa,Roa,Soa,v0,w0,y0,z0,A0,B0,C0,D0,m0,Uoa,Voa,G0,Woa,H0,Xoa,J0,Yoa,Zoa,$oa,apa,bpa;Poa=function(a){var b=m0;return $CLJS.ZF(function(c){return function(d,e,f){f=b.g?b.g(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};o0=function(a){var b=n0;return $CLJS.ZF(function(c){return function(d,e,f){e=b.g?b.g(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};
p0=function(a){if($CLJS.de(a))return a;throw $CLJS.zj("Invalid pattern: don't know how to handle symbol.",new $CLJS.k(null,1,[$CLJS.nk,a],null));};q0=function(a){var b=$CLJS.ne(a);return b?(b=!$CLJS.Ig(a))?$CLJS.Nm.h($CLJS.pf,$CLJS.Jb)($CLJS.C(a)):b:b};n0=function(a){return $CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(a))?$CLJS.G_(a):a};r0=function(a,b){var c=q0(b);return $CLJS.m(c)?(b=n0($CLJS.C(b)),$CLJS.ke(a)?(a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,b)):c};
Qoa=function(a){return $CLJS.M.j(new $CLJS.k(null,3,[$CLJS.Pj,$CLJS.Ye,$CLJS.Vk,n0,$CLJS.bJ,n0],null),a,function(b){var c=$CLJS.M,d=c.h;b=$CLJS.Pf([a,b]);b=s0.h?s0.h(b,t0):s0.call(null,b,t0);return d.call(c,b,a)})};
Roa=function(a){a=$CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=n0(c);var d=Qoa(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,d.g?d.g(b):d.call(null,b)],null)}),a);return $CLJS.F.h($CLJS.Vk.g(a),$CLJS.bI)&&$CLJS.Gb($CLJS.bJ.g(a))?$CLJS.U.j(a,$CLJS.bJ,$CLJS.DR):a};
Soa=function(a){return $CLJS.oh.j($CLJS.P,$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);c=$CLJS.d_(c);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.U.j(Roa(b),$CLJS.V,c)],null)}),a)};v0=function(a){return $CLJS.u0.j($CLJS.u0.j($CLJS.Sb(function(b,c){return $CLJS.u0.j(b,c,$CLJS.Oi)},a,new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.sJ,$CLJS.ega,$CLJS.BP,Toa,$CLJS.rF,$CLJS.Wj],null)),$CLJS.JG,$CLJS.Lm.h(m0,s0)),$CLJS.dP,$CLJS.WZ)};
w0=function(a){return $CLJS.Ae(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,null],null):a};y0=function(a){return x0.g(w0(a))};z0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.f_($CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.h(x0,b)))};A0=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[a,y0(b)],null),$CLJS.rg.h(x0,c))};
B0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.S(null,2,5,$CLJS.T,[b,y0(a)],null):new $CLJS.S(null,1,5,$CLJS.T,[b],null)};C0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,y0(a)],null)};D0=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[b,x0.g(a)],null)};
m0=function(a){return E0(function(b){if($CLJS.oe(b))return Poa(b);if($CLJS.Gb(q0(b)))return b;try{return x0.g(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.XF($CLJS.Vu);if($CLJS.m($CLJS.dG("metabase.mbql.normalize",d))){var e=$CLJS.UH("Invalid clause:");e instanceof Error?$CLJS.eG("metabase.mbql.normalize",d,$CLJS.UB.l($CLJS.G([b])),e):$CLJS.eG("metabase.mbql.normalize",d,$CLJS.UB.l($CLJS.G([e,b])),null)}throw $CLJS.yj($CLJS.TH("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.RZ(c)])),new $CLJS.k(null,
1,[$CLJS.iS,b],null),c);}throw f;}},a)};Uoa=function(a){return $CLJS.Mg($CLJS.eg(m0,F0(a)))};Voa=function(a){for(;;)if($CLJS.m(q0(a)))a=new $CLJS.S(null,1,5,$CLJS.T,[a],null);else return $CLJS.Rf($CLJS.Pm.h(w0,a))};G0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.bI);return $CLJS.m(b)?$CLJS.Qm.j(a,$CLJS.bI,x0):a};
Woa=function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[u,G0(q)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,
2,5,$CLJS.T,[l,G0(f)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())};H0=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.hR);return $CLJS.m(b)?$CLJS.Qm.j(a,$CLJS.hR,Woa):a};Xoa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.TQ);b=$CLJS.Gb(a)?I0.g?I0.g(b):I0.call(null,b):b;return $CLJS.m(a)?H0(b):b};J0=function(a){return $CLJS.ke(a)?$CLJS.A(a):null!=a};
Yoa=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.uO);var c=$CLJS.M.h(b,$CLJS.NO),d=$CLJS.M.h(b,$CLJS.NP),e=$CLJS.M.h(b,$CLJS.TQ);try{if($CLJS.m(d)){var f=$CLJS.Qf(b),g=$CLJS.M.h(f,$CLJS.NP);var l=$CLJS.Ou($CLJS.Dm.h(f,$CLJS.NP),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uO,$CLJS.NP],null),g)}else l=b;var n=$CLJS.m(a)?$CLJS.Qm.j(l,$CLJS.uO,I0):l,q=$CLJS.m(c)?$CLJS.Qm.j(n,$CLJS.NO,$CLJS.bg($CLJS.Pm,m0)):n,u=$CLJS.m(e)?$CLJS.Qm.j(q,$CLJS.TQ,H0):q;return m0(u)}catch(v){if(v instanceof Error)throw n=v,$CLJS.yj($CLJS.TH("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.RZ(n)])),new $CLJS.k(null,1,[$CLJS.uO,a],null),n);throw v;}};
Zoa=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.uO),c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.FP);c=$CLJS.M.h(c,$CLJS.wO);return $CLJS.A(b)&&$CLJS.A(c)?(b=$CLJS.si($CLJS.Ve($CLJS.qg,$CLJS.A($CLJS.ug($CLJS.Hb,function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{var l=$CLJS.Td(f,0);if($CLJS.O(l,$CLJS.DQ)){var n=$CLJS.Td(f,1),q=$CLJS.Td(f,2);return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[f,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,n,$CLJS.Dm.h(q,$CLJS.PQ)],null)],null)],null)}throw $CLJS.Z;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw u;}else throw $CLJS.Z;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Z)return $CLJS.qZ(g,e,f);throw l;}throw u;}}($CLJS.Lg,b))))),$CLJS.K0.j(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uO,$CLJS.wO],null),$CLJS.Lm.h($CLJS.Mg,$CLJS.bg($CLJS.Om,b)))):a};
$oa=function(a){try{return Zoa(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.yj($CLJS.UH("Error performing whole query transformations"),new $CLJS.k(null,1,[$CLJS.uO,a],null),b);}throw c;}};
apa=function(a,b){var c=$CLJS.oh.h($CLJS.ae(a),function(){return function f(e){return new $CLJS.yf(null,function(){for(var g=e;;)if(g=$CLJS.A(g)){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);return function(){for(var y=0;;)if(y<n){var B=$CLJS.be(l,y),H=$CLJS.J(B,0,null);B=$CLJS.J(B,1,null);var I=$CLJS.kf.h(b,H);B=L0.h?L0.h(B,I):L0.call(null,B,I);null!=B&&q.add(new $CLJS.S(null,2,5,$CLJS.T,[H,B],null));y+=1}else return!0}()?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),
null)}var u=$CLJS.C(g),v=$CLJS.J(u,0,null),x=$CLJS.J(u,1,null);u=function(){var y=x,B=$CLJS.kf.h(b,v);return L0.h?L0.h(y,B):L0.call(null,y,B)}();if(null!=u)return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[v,u],null),f($CLJS.zd(g)));g=$CLJS.zd(g)}else return null},null,null)}(a)}());return $CLJS.A(c)?c:null};bpa=function(a,b){a=$CLJS.Pm.h(function(c){var d=$CLJS.kf.h(b,M0);return L0.h?L0.h(c,d):L0.call(null,c,d)},a);return $CLJS.m($CLJS.Xf($CLJS.Hb,a))?a:null};
$CLJS.K0=function K0(a){switch(arguments.length){case 3:return K0.j(arguments[0],arguments[1],arguments[2]);case 4:return K0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return K0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return K0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return K0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.K0.j=function(a,b,c){var d=$CLJS.A(b);b=$CLJS.C(d);if(d=$CLJS.D(d))a=$CLJS.U.j(a,b,$CLJS.K0.j($CLJS.M.h(a,b),d,c));else{d=$CLJS.U.j;var e=$CLJS.M.h(a,b);c=c.g?c.g(e):c.call(null,e);a=d.call($CLJS.U,a,b,c)}return a};
$CLJS.K0.D=function(a,b,c,d){var e=$CLJS.A(b);b=$CLJS.C(e);if(e=$CLJS.D(e))a=$CLJS.U.j(a,b,$CLJS.K0.D($CLJS.M.h(a,b),e,c,d));else{e=$CLJS.U.j;var f=$CLJS.M.h(a,b);c=c.h?c.h(f,d):c.call(null,f,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.K0.R=function(a,b,c,d,e){var f=$CLJS.A(b);b=$CLJS.C(f);if(f=$CLJS.D(f))a=$CLJS.U.j(a,b,$CLJS.K0.R($CLJS.M.h(a,b),f,c,d,e));else{f=$CLJS.U.j;var g=$CLJS.M.h(a,b);c=c.j?c.j(g,d,e):c.call(null,g,d,e);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.K0.aa=function(a,b,c,d,e,f){var g=$CLJS.A(b);b=$CLJS.C(g);if(g=$CLJS.D(g))a=$CLJS.U.j(a,b,$CLJS.K0.aa($CLJS.M.h(a,b),g,c,d,e,f));else{g=$CLJS.U.j;var l=$CLJS.M.h(a,b);c=c.D?c.D(l,d,e,f):c.call(null,l,d,e,f);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.K0.l=function(a,b,c,d,e,f,g){var l=$CLJS.A(b);b=$CLJS.C(l);return(l=$CLJS.D(l))?$CLJS.U.j(a,b,$CLJS.R.l($CLJS.K0,$CLJS.M.h(a,b),l,c,d,$CLJS.G([e,f,g]))):$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.M.h(a,b),d,e,f,$CLJS.G([g])))};
$CLJS.K0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.K0.v=6;
$CLJS.u0=function u0(a){switch(arguments.length){case 3:return u0.j(arguments[0],arguments[1],arguments[2]);case 4:return u0.D(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return u0.R(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return u0.aa(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return u0.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.z(c.slice(6),0,null))}};$CLJS.u0.j=function(a,b,c){var d=$CLJS.Ke(a,b);if($CLJS.m(d)){var e=$CLJS.U.j;d=$CLJS.uc(d);c=c.g?c.g(d):c.call(null,d);a=e.call($CLJS.U,a,b,c)}return a};$CLJS.u0.D=function(a,b,c,d){var e=$CLJS.Ke(a,b);if($CLJS.m(e)){var f=$CLJS.U.j;e=$CLJS.uc(e);c=c.h?c.h(e,d):c.call(null,e,d);a=f.call($CLJS.U,a,b,c)}return a};
$CLJS.u0.R=function(a,b,c,d,e){var f=$CLJS.Ke(a,b);if($CLJS.m(f)){var g=$CLJS.U.j;f=$CLJS.uc(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=g.call($CLJS.U,a,b,c)}return a};$CLJS.u0.aa=function(a,b,c,d,e,f){var g=$CLJS.Ke(a,b);if($CLJS.m(g)){var l=$CLJS.U.j;g=$CLJS.uc(g);c=c.D?c.D(g,d,e,f):c.call(null,g,d,e,f);a=l.call($CLJS.U,a,b,c)}return a};$CLJS.u0.l=function(a,b,c,d,e,f,g){var l=$CLJS.Ke(a,b);return $CLJS.m(l)?$CLJS.U.j(a,b,$CLJS.R.l(c,$CLJS.uc(l),d,e,f,$CLJS.G([g]))):a};
$CLJS.u0.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);var e=$CLJS.D(d);d=$CLJS.C(e);var f=$CLJS.D(e);e=$CLJS.C(f);var g=$CLJS.D(f);f=$CLJS.C(g);g=$CLJS.D(g);return this.l(b,a,c,d,e,f,g)};$CLJS.u0.v=6;
var E0=function E0(a,b){return $CLJS.UZ($CLJS.bg(E0,a),$CLJS.Ye,a.g?a.g(b):a.call(null,b))},cpa=new $CLJS.N(null,"value_field","value_field",-980977878),N0=new $CLJS.N(null,"ascending","ascending",-988350486),O0=new $CLJS.N(null,"named","named",-422393479),P0=new $CLJS.N(null,"values_source_config","values_source_config",-590570309),dpa=new $CLJS.N(null,"field-id","field-id",-353751335),Q0=new $CLJS.N(null,"descending","descending",-24766135),R0=new $CLJS.N(null,"binning-strategy","binning-strategy",
2063329158),S0=new $CLJS.N(null,"datetime-field","datetime-field",21731696),epa=new $CLJS.N(null,"use-as-display-name?","use-as-display-name?",686752941),T0=new $CLJS.N(null,"field-literal","field-literal",-1295883554),U0=new $CLJS.N(null,"viz-settings","viz-settings",256055379),fpa=new $CLJS.N(null,"rows","rows",850049680),gpa=new $CLJS.N(null,"special-fn","special-fn",1290649344),M0=new $CLJS.N("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),t0=new $CLJS.N(null,
"ignore-path","ignore-path",944069061),hpa=new $CLJS.N(null,"label_field","label_field",-1573182765),ipa=new $CLJS.N(null,"fk-\x3e","fk-\x3e",-499026738),jpa=new $CLJS.N(null,"joined-field","joined-field",-2048778268),Toa=new $CLJS.N(null,"visibility_type","visibility_type",-508434247);var V0,kpa=$CLJS.cj.g($CLJS.P),lpa=$CLJS.cj.g($CLJS.P),mpa=$CLJS.cj.g($CLJS.P),npa=$CLJS.cj.g($CLJS.P),opa=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));V0=new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Lm.h(n0,$CLJS.C),$CLJS.Pj,opa,kpa,lpa,mpa,npa);V0.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,b],null);return null!=a?$CLJS.kf.h(b,a):b});
V0.o(null,$CLJS.zG,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.zG,a instanceof $CLJS.N?$CLJS.d_(a):a],null)});V0.o(null,R0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?$CLJS.kf.h(V0.g(new $CLJS.S(null,3,5,$CLJS.T,[R0,b,c],null)),a):new $CLJS.S(null,3,5,$CLJS.T,[R0,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c)],null)});
V0.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=s0.h?s0.h(a,t0):s0.call(null,a,t0);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,function(){var d=$CLJS.m($CLJS.CG.g(c))?$CLJS.Qm.j(c,$CLJS.CG,$CLJS.Oi):c;d=$CLJS.m($CLJS.PQ.g(c))?$CLJS.Qm.j(d,$CLJS.PQ,$CLJS.Oi):d;return $CLJS.m($CLJS.OR.g(c))?$CLJS.Qm.j(d,$CLJS.OR,function(e){return $CLJS.m($CLJS.VK.g(e))?$CLJS.Qm.j(e,$CLJS.VK,$CLJS.Oi):e}):d}()],null)});
V0.o(null,T0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[T0,b instanceof $CLJS.N?$CLJS.d_(b):b,$CLJS.Oi.g(a)],null)});V0.o(null,S0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[S0,s0.h?s0.h(b,t0):s0.call(null,b,t0),$CLJS.vu,n0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[S0,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c)],null)});
V0.o(null,$CLJS.pK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);return $CLJS.m(a)?$CLJS.kf.h(V0.g(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.pK,b,c,d],null)),s0.h?s0.h(a,t0):s0.call(null,a,t0)):new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.pK,s0.h?s0.h(b,t0):s0.call(null,b,t0),$CLJS.Ae(c)?c:n0(c),n0(d)],null)});
V0.o(null,$CLJS.qJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qJ,b,n0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.qJ,$CLJS.mB],null)});V0.o(null,$CLJS.hJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hJ,b,n0(a)],null)});
V0.o(null,$CLJS.cK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.cK,s0.h?s0.h(b,t0):s0.call(null,b,t0),c,n0(a)],null)});V0.o(null,$CLJS.KK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.KK,s0.h?s0.h(b,t0):s0.call(null,b,t0),c,n0(a)],null)});
V0.o(null,$CLJS.bK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bK,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(a)],null):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bK,s0.h?s0.h(b,t0):s0.call(null,b,t0)],null)});
V0.o(null,$CLJS.nK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.m(a)?new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.nK,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c),n0(a)],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nK,s0.h?s0.h(b,t0):s0.call(null,b,t0),n0(c)],null)});
V0.o(null,$CLJS.gJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.gJ,s0.h?s0.h(b,t0):s0.call(null,b,t0),s0.h?s0.h(c,t0):s0.call(null,c,t0),n0(a)],null)});V0.o(null,$CLJS.pl,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pl,b,a],null)});
V0.o(null,$CLJS.Pj,function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[n0(a)],null),$CLJS.rg.g(function(c){return s0.h?s0.h(c,t0):s0.call(null,c,t0)}),b)});
var W0=function W0(a){if($CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(a))){var c=n0(a);var d=new $CLJS.ni(null,new $CLJS.k(null,18,[$CLJS.qk,null,$CLJS.lm,null,$CLJS.yK,null,$CLJS.dK,null,$CLJS.wu,null,$CLJS.LJ,null,$CLJS.MK,null,$CLJS.OK,null,$CLJS.LI,null,$CLJS.lJ,null,$CLJS.km,null,$CLJS.PI,null,$CLJS.tK,null,$CLJS.XJ,null,$CLJS.Xl,null,$CLJS.kD,null,$CLJS.BJ,null,$CLJS.rK,null],null),null);c=d.g?d.g(c):d.call(null,c)}else c=null;return $CLJS.m(c)?c:$CLJS.m(q0(a))?(a=$CLJS.C(a),W0.g?W0.g(a):W0.call(null,
a)):null},ppa=new $CLJS.k(null,8,[$CLJS.Vk,n0,$CLJS.TQ,function(a){a=o0(a);return $CLJS.A($CLJS.hR.g(a))?$CLJS.Qm.j(a,$CLJS.hR,Soa):a},$CLJS.uO,new $CLJS.k(null,6,[$CLJS.MP,function X0(a){if($CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(a)))return n0(a);if($CLJS.m(r0(O0,a))){a=$CLJS.A(a);$CLJS.C(a);var c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[O0,X0.g?X0.g(a):X0.call(null,a)],null),c)}return $CLJS.m($CLJS.m(q0(a))?W0($CLJS.Zd(a)):null)?$CLJS.Pm.h(X0,a):s0.h?s0.h(a,
t0):s0.call(null,a,t0)},$CLJS.OQ,function(a){return $CLJS.oh.h($CLJS.P,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.d_(u),s0.h?s0.h(q,t0):s0.call(null,q,t0)],null);l.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=
$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.d_(l),s0.h?s0.h(f,t0):s0.call(null,f,t0)],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.YR,function(a){return $CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n);q=$CLJS.m(q0(q))?V0.g(q):$CLJS.lf(V0.g($CLJS.lf(q)));l.add(q);
n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}l=$CLJS.C(e);return $CLJS.nf($CLJS.m(q0(l))?V0.g(l):$CLJS.lf(V0.g($CLJS.lf(l))),d($CLJS.zd(e)))}return null}},null,null)}(a)}())},$CLJS.kR,function(a){a=o0(a);a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.TQ);if($CLJS.m(b))return a=$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.TQ],null),a=s0.h?s0.h(a,b):s0.call(null,a,b),$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.uO,$CLJS.TQ],
null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uO],null);return s0.h?s0.h(a,b):s0.call(null,a,b)},$CLJS.NP,new $CLJS.k(null,1,[M0,v0],null),$CLJS.UO,new $CLJS.k(null,1,[M0,function(a){a=s0.h?s0.h(a,$CLJS.uO):s0.call(null,a,$CLJS.uO);var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.VK),d=$CLJS.M.h(b,$CLJS.wO);a=$CLJS.M.h(b,$CLJS.vP);b=$CLJS.m(c)?$CLJS.Qm.j(b,$CLJS.VK,n0):b;d=$CLJS.m($CLJS.Nm.h($CLJS.pf,$CLJS.Jb)(d))?$CLJS.Qm.j(b,$CLJS.wO,n0):b;return $CLJS.m(a)?$CLJS.Qm.j(d,$CLJS.vP,$CLJS.d_):d}],null)],null),
$CLJS.ZD,new $CLJS.k(null,1,[$CLJS.nP,$CLJS.Ye],null),$CLJS.NO,new $CLJS.k(null,1,[M0,function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Vk),d=$CLJS.M.h(b,$CLJS.ZK),e=$CLJS.M.h(b,$CLJS.Gk);a=$CLJS.M.h(b,P0);b=$CLJS.m(e)?$CLJS.Qm.j(b,$CLJS.Gk,$CLJS.d_):b;c=$CLJS.m(c)?$CLJS.Qm.j(b,$CLJS.Vk,n0):b;d=$CLJS.m(d)?$CLJS.Qm.j(c,$CLJS.ZK,function(f){return s0.h?s0.h(f,t0):s0.call(null,f,t0)}):c;d=$CLJS.m(a)?$CLJS.K0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[P0,hpa],null),function(f){return s0.h?s0.h(f,t0):s0.call(null,
f,t0)}):d;return $CLJS.m(a)?$CLJS.K0.j(d,new $CLJS.S(null,2,5,$CLJS.T,[P0,cpa],null),function(f){return s0.h?s0.h(f,t0):s0.call(null,f,t0)}):d}],null),$CLJS.LO,function(a){return null==a?null:n0(a)},$CLJS.NP,new $CLJS.k(null,1,[M0,v0],null),U0,n0],null),s0=function s0(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return s0.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
s0.l=function(a,b){b=$CLJS.J(b,0,null);var c=b instanceof $CLJS.N?new $CLJS.S(null,1,5,$CLJS.T,[b],null):$CLJS.Mg(b);b=$CLJS.A(c)?$CLJS.Iu(ppa,c):null;try{return $CLJS.de(b)?b.g?b.g(a):b.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?$CLJS.oh.h($CLJS.P,function(){return function g(f){return new $CLJS.yf(null,function(){for(;;){var l=$CLJS.A(f);if(l){if($CLJS.re(l)){var n=$CLJS.$c(l),q=$CLJS.E(n),u=$CLJS.Bf(q);a:for(var v=0;;)if(v<q){var x=$CLJS.be(n,v),y=$CLJS.J(x,0,null);x=$CLJS.J(x,1,null);y=n0(y);y=new $CLJS.S(null,
2,5,$CLJS.T,[y,s0.l(x,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),y)]))],null);u.add(y);v+=1}else{n=!0;break a}return n?$CLJS.Ef($CLJS.Gf(u),g($CLJS.ad(l))):$CLJS.Ef($CLJS.Gf(u),null)}n=$CLJS.C(l);u=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);u=n0(u);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[u,s0.l(n,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),u)]))],null),g($CLJS.zd(l)))}return null}},null,null)}(a)}()):$CLJS.m(q0(a))?V0.g(a):$CLJS.ne(a)?$CLJS.Pm.h(function(e){return s0.l(e,$CLJS.G([$CLJS.kf.h($CLJS.Mg(c),M0)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.yj($CLJS.TH("Error normalizing form: {0}",$CLJS.G([$CLJS.RZ(d)])),new $CLJS.k(null,3,[$CLJS.qF,a,$CLJS.Zl,c,gpa,b],null),d);}throw e;}};s0.v=1;s0.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var x0=function(){var a=$CLJS.cj.g($CLJS.P),b=$CLJS.cj.g($CLJS.P),c=$CLJS.cj.g($CLJS.P),d=$CLJS.cj.g($CLJS.P),e=$CLJS.M.j($CLJS.P,$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));return new $CLJS.vj($CLJS.Ui.h("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.m(q0(f))?$CLJS.C(f):null},$CLJS.Pj,e,a,b,c,d)}();x0.o(null,$CLJS.Pj,function(a){return a});
x0.o(null,$CLJS.DQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);if($CLJS.m(r0($CLJS.DQ,b))){$CLJS.J(b,0,null);var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);return x0.g(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,c,$CLJS.Rf($CLJS.fn.l($CLJS.G([b,a])))],null))}return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,$CLJS.Rf(a)],null)});
x0.o(null,$CLJS.MP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.je(a)?new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.MP,b],null):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MP,b,a],null)});x0.o(null,dpa,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.m(q0(a))?x0.g(a):new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,null],null)});
x0.o(null,T0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,new $CLJS.k(null,1,[$CLJS.CG,a],null)],null)});x0.o(null,ipa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);var c=y0(b);$CLJS.J(c,0,null);b=$CLJS.J(c,1,null);$CLJS.J(c,2,null);c=y0(a);$CLJS.J(c,0,null);a=$CLJS.J(c,1,null);c=$CLJS.J(c,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,a,$CLJS.U.j(c,$CLJS.VO,b)],null)});
x0.o(null,jpa,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=y0(a);return $CLJS.R.D($CLJS.q_,a,$CLJS.U,$CLJS.G([$CLJS.lQ,b]))});
x0.o(null,S0,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.J(a,0,null);b=$CLJS.J(a,1,null);var c=$CLJS.J(a,2,null);a=y0(b);b=c;$CLJS.J(a,0,null);$CLJS.J(a,1,null);c=$CLJS.J(a,2,null);c=$CLJS.Qf(c);c=$CLJS.M.h(c,$CLJS.CG);return $CLJS.Gb(c)||$CLJS.GS.h(c,b)?$CLJS.R.D($CLJS.q_,a,$CLJS.U,$CLJS.G([$CLJS.PQ,b])):a;case 4:return $CLJS.J(a,0,null),b=$CLJS.J(a,1,null),$CLJS.J(a,2,null),c=$CLJS.J(a,3,null),x0.g(new $CLJS.S(null,3,5,$CLJS.T,[S0,b,c],null));default:throw Error(["No matching clause: ",
$CLJS.p.g(b)].join(""));}});x0.o(null,R0,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null),d=$CLJS.J(a,3,null);a=$CLJS.J(a,4,null);var e=y0(b);$CLJS.J(e,0,null);b=$CLJS.J(e,1,null);e=$CLJS.J(e,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DQ,b,$CLJS.U.j(e,$CLJS.OR,$CLJS.fn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.VK,c],null),$CLJS.m(d)?$CLJS.Pf([c,d]):null,a])))],null)});
for(var Y0=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.Bk,$CLJS.Sk],null)),Z0=null,$0=0,a1=0;;)if(a1<$0){var b1=Z0.$(null,a1);x0.o(null,b1,function(){return function(a){return z0(a)}}(Y0,Z0,$0,a1,b1));a1+=1}else{var c1=$CLJS.A(Y0);if(c1){var d1=c1;if($CLJS.re(d1)){var e1=$CLJS.$c(d1),qpa=$CLJS.ad(d1),rpa=e1,spa=$CLJS.E(e1);Y0=qpa;Z0=rpa;$0=spa}else{var f1=$CLJS.C(d1);x0.o(null,f1,function(){return function(a){return z0(a)}}(Y0,Z0,$0,a1,f1,d1,c1));Y0=$CLJS.D(d1);Z0=null;$0=0}a1=0}else break}
x0.o(null,$CLJS.TI,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TI,y0(a),y0(b)],null),c)});x0.o(null,$CLJS.pK,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=y0(a);a=$CLJS.m($CLJS.e_($CLJS.DQ,a))?$CLJS.q_.l(c,$CLJS.Dm,$CLJS.G([$CLJS.PQ])):c;return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pK,a],null),b)});
for(var g1=$CLJS.A(new $CLJS.S(null,15,5,$CLJS.T,[$CLJS.tJ,$CLJS.lK,$CLJS.JJ,$CLJS.uJ,$CLJS.fm,$CLJS.BK,$CLJS.gm,$CLJS.sm,$CLJS.im,$CLJS.rm,$CLJS.JI,$CLJS.ZI,$CLJS.wI,$CLJS.mJ,$CLJS.II],null)),h1=null,i1=0,j1=0;;)if(j1<i1){var k1=h1.$(null,j1);x0.o(null,k1,function(){return function(a){return A0(a)}}(g1,h1,i1,j1,k1));j1+=1}else{var l1=$CLJS.A(g1);if(l1){var m1=l1;if($CLJS.re(m1)){var n1=$CLJS.$c(m1),tpa=$CLJS.ad(m1),upa=n1,vpa=$CLJS.E(n1);g1=tpa;h1=upa;i1=vpa}else{var o1=$CLJS.C(m1);x0.o(null,o1,
function(){return function(a){return A0(a)}}(g1,h1,i1,j1,o1,m1,l1));g1=$CLJS.D(m1);h1=null;i1=0}j1=0}else break}x0.o(null,fpa,function(){return null});x0.o(null,$CLJS.nO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.nO,x0.g(b),a],null)});
x0.o(null,O0,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);c=x0.g;var e=$CLJS.T;b=x0.g(b);d=$CLJS.J(d,0,null);d=$CLJS.Qf(d);a=!1===$CLJS.M.h(d,epa)?new $CLJS.k(null,1,[$CLJS.V,a],null):new $CLJS.k(null,1,[$CLJS.SI,a],null);return c.call(x0,new $CLJS.S(null,3,5,e,[$CLJS.nO,b,a],null))});
for(var p1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kD,$CLJS.LJ],null)),q1=null,r1=0,s1=0;;)if(s1<r1){var t1=q1.$(null,s1);x0.o(null,t1,function(){return function(a){return B0(a)}}(p1,q1,r1,s1,t1));s1+=1}else{var u1=$CLJS.A(p1);if(u1){var v1=u1;if($CLJS.re(v1)){var w1=$CLJS.$c(v1),wpa=$CLJS.ad(v1),xpa=w1,ypa=$CLJS.E(w1);p1=wpa;q1=xpa;r1=ypa}else{var x1=$CLJS.C(v1);x0.o(null,x1,function(){return function(a){return B0(a)}}(p1,q1,r1,s1,x1,v1,u1));p1=$CLJS.D(v1);q1=null;r1=0}s1=0}else break}
for(var y1=$CLJS.A(new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.BJ,$CLJS.PK,$CLJS.LI,$CLJS.yK,$CLJS.rK,$CLJS.qk,$CLJS.Xl,$CLJS.tK,$CLJS.PI],null)),z1=null,A1=0,B1=0;;)if(B1<A1){var C1=z1.$(null,B1);x0.o(null,C1,function(){return function(a){return C0(a)}}(y1,z1,A1,B1,C1));B1+=1}else{var D1=$CLJS.A(y1);if(D1){var E1=D1;if($CLJS.re(E1)){var F1=$CLJS.$c(E1),zpa=$CLJS.ad(E1),Apa=F1,Bpa=$CLJS.E(F1);y1=zpa;z1=Apa;A1=Bpa}else{var G1=$CLJS.C(E1);x0.o(null,G1,function(){return function(a){return C0(a)}}(y1,z1,A1,B1,
G1,E1,D1));y1=$CLJS.D(E1);z1=null;A1=0}B1=0}else break}x0.o(null,$CLJS.lJ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lJ,y0(b),a],null)});
for(var H1=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.XJ,$CLJS.dK],null)),I1=null,J1=0,K1=0;;)if(K1<J1){var L1=I1.$(null,K1);x0.o(null,L1,function(){return function(a){return D0(a)}}(H1,I1,J1,K1,L1));K1+=1}else{var M1=$CLJS.A(H1);if(M1){var N1=M1;if($CLJS.re(N1)){var O1=$CLJS.$c(N1),Cpa=$CLJS.ad(N1),Dpa=O1,Epa=$CLJS.E(O1);H1=Cpa;I1=Dpa;J1=Epa}else{var P1=$CLJS.C(N1);x0.o(null,P1,function(){return function(a){return D0(a)}}(H1,I1,J1,K1,P1,N1,M1));H1=$CLJS.D(N1);I1=null;J1=0}K1=0}else break}
x0.o(null,$CLJS.MK,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.MK,x0.g(b),x0.g(a)],null)});
x0.o(null,$CLJS.YI,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.m(a)?$CLJS.kf.h(x0.g(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.YI,b],null)),s0.l(a,$CLJS.G([t0]))):new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.YI,$CLJS.Mg(function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=new $CLJS.S(null,
2,5,$CLJS.T,[x0.g(v),x0.g(u)],null);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[x0.g(n),x0.g(g)],null),e($CLJS.zd(f)))}return null}},null,null)}(b)}())],null)});
x0.o(null,$CLJS.$H,function(a){a=$CLJS.A(a);$CLJS.C(a);var b=$CLJS.D(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$H,x0.g(a),$CLJS.F.h(0,b)?1:x0.g(b)],null),$CLJS.rg.h(x0,c))});
var F0=function F0(a){return function f(d,e){try{if($CLJS.m(function(){var x=p0($CLJS.we);return x.g?x.g(e):x.call(null,e)}()))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Z)try{if($CLJS.m(function(){var y=p0($CLJS.pf);return y.g?y.g(e):y.call(null,e)}()))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[e],null)],null);throw $CLJS.Z;}catch(y){if(y instanceof Error)if(d=y,d===$CLJS.Z)try{if($CLJS.qe(e)&&2<=$CLJS.E(e))try{var g=$CLJS.Vm.j(e,
0,2);if($CLJS.qe(g)&&2===$CLJS.E(g))try{var l=$CLJS.Td(g,0);if($CLJS.m($CLJS.Mm.h($CLJS.pf,$CLJS.$f(new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.lm,null,$CLJS.wu,null,$CLJS.OK,null,$CLJS.km,null,O0,null],null),null)))(l)))try{var n=$CLJS.Td(g,1);if($CLJS.m(W0(n)))return $CLJS.Mg($CLJS.Ve($CLJS.qg,$CLJS.rg.h(F0,a)));throw $CLJS.Z;}catch(B){if(B instanceof Error){var q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;
throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error){q=B;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw B;}else throw $CLJS.Z;}catch(B){if(B instanceof Error)if(q=B,q===$CLJS.Z)try{if($CLJS.qe(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Vm.j(e,0,1);if($CLJS.qe(u)&&1===$CLJS.E(u))try{if($CLJS.Td(u,0)instanceof $CLJS.N)return new $CLJS.S(null,1,5,$CLJS.T,[e],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof
Error){v=H;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){v=H;if(v===$CLJS.Z)return e;throw v;}throw H;}else throw q;else throw B;}else throw d;else throw y;}else throw d;else throw x;}}($CLJS.Lg,a)},Q1=function Q1(a){return function f(d,e){try{var g=p0($CLJS.we);var l=g.g?g.g(e):g.call(null,e);if($CLJS.m(l))return f(d,$CLJS.Mg(e));throw $CLJS.Z;}catch(B){if(B instanceof Error)if(l=B,l===$CLJS.Z)try{if($CLJS.qe(e)&&2===$CLJS.E(e))try{var n=$CLJS.Td(e,
1);if($CLJS.O(n,$CLJS.EJ)){var q=$CLJS.Td(e,0);return f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EJ,q],null))}throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,$CLJS.rJ))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rJ,q],null));throw $CLJS.Z;}catch(I){if(I instanceof Error){var u=I;if(u===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,N0))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EJ,q],null));throw $CLJS.Z;}catch(Q){if(Q instanceof
Error)if(l=Q,l===$CLJS.Z)try{n=$CLJS.Td(e,1);if($CLJS.O(n,Q0))return q=$CLJS.Td(e,0),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rJ,q],null));throw $CLJS.Z;}catch(Y){if(Y instanceof Error)if(n=Y,n===$CLJS.Z)try{var v=$CLJS.Td(e,0);if($CLJS.O(v,N0))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EJ,q],null));throw $CLJS.Z;}catch(aa){if(aa instanceof Error)if(n=aa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,Q0))return q=$CLJS.Td(e,1),f(d,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rJ,q],null));
throw $CLJS.Z;}catch(ha){if(ha instanceof Error)if(n=ha,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.EJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.EJ,y0(q)],null);throw $CLJS.Z;}catch(qa){if(qa instanceof Error)if(n=qa,n===$CLJS.Z)try{v=$CLJS.Td(e,0);if($CLJS.O(v,$CLJS.rJ))return q=$CLJS.Td(e,1),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rJ,y0(q)],null);throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){q=Ea;if(q===$CLJS.Z)throw $CLJS.Z;throw q;}throw Ea;}else throw n;else throw qa;
}else throw n;else throw ha;}else throw n;else throw aa;}else throw n;else throw Y;}else throw l;else throw Q;}else throw u;}else throw I;}else throw l;else throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error)if(l=H,l===$CLJS.Z)try{if($CLJS.qe(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Vm.j(e,0,0);if($CLJS.qe(x)&&0===$CLJS.E(x))try{var y=$CLJS.Vm.h(e,0);if($CLJS.F.h(y,a))return $CLJS.Mg($CLJS.hn.g($CLJS.rg.h(Q1,a)));throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;
}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)throw $CLJS.Z;throw u;}throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error){u=I;if(u===$CLJS.Z)return $CLJS.c_(f,d,e);throw u;}throw I;}else throw l;else throw H;}else throw l;else throw B;}}($CLJS.Lg,a)},I0=$CLJS.Lm.h(m0,function(a){var b=J0($CLJS.MP.g(a))?$CLJS.Qm.j(a,$CLJS.MP,Uoa):a;b=J0($CLJS.FP.g(a))?$CLJS.Qm.j(b,$CLJS.FP,Voa):b;b=J0($CLJS.wO.g(a))?$CLJS.Qm.j(b,$CLJS.wO,$CLJS.bg($CLJS.Pm,w0)):b;b=J0($CLJS.YR.g(a))?
$CLJS.Qm.j(b,$CLJS.YR,Q1):b;return J0($CLJS.kR.g(a))?$CLJS.Qm.j(b,$CLJS.kR,Xoa):b}),Fpa=new $CLJS.k(null,3,[$CLJS.TQ,$CLJS.Ye,$CLJS.uO,new $CLJS.k(null,2,[$CLJS.kR,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.TQ);if($CLJS.m(b))return a=$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.TQ,$CLJS.uO],null)),b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.TQ],null),a=L0.h?L0.h(a,b):L0.call(null,a,b),$CLJS.PN(a,new $CLJS.k(null,1,[$CLJS.uO,$CLJS.TQ],null));b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uO],null);return L0.h?L0.h(a,
b):L0.call(null,a,b)},$CLJS.UO,new $CLJS.k(null,1,[M0,function(a){var b=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.uO],null);return L0.h?L0.h(a,b):L0.call(null,a,b)}],null)],null),U0,$CLJS.Ye],null),L0=function L0(a){switch(arguments.length){case 1:return L0.g(arguments[0]);case 2:return L0.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};L0.g=function(a){return L0.h(a,$CLJS.Lg)};
L0.h=function(a,b){try{var c=$CLJS.A(b)?$CLJS.Iu(Fpa,b):null;return $CLJS.de(c)?c.g?c.g(a):c.call(null,a):$CLJS.pe(a)?a:$CLJS.oe(a)?apa(a,b):$CLJS.ne(a)?bpa(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.yj("Error removing empty clauses from form.",new $CLJS.k(null,2,[$CLJS.qF,a,$CLJS.Zl,b],null),d);throw d;}};L0.v=2;
$CLJS.R1=function(){var a=$CLJS.Lm.l(L0,$oa,Yoa,$CLJS.G([s0]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.yj($CLJS.TH("Error normalizing query: {0}",$CLJS.G([$CLJS.RZ(c)])),new $CLJS.k(null,1,[$CLJS.uO,b],null),c);}throw d;}}}();